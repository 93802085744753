import React, { useState } from 'react';
import { Edit, Plus } from 'lucide-react';
import DescriptionGeneratorModal from './DescriptionGeneratorModal';
import ImageUploadModal from './ImageUploadModal';

const MenuItem = ({ name, price, description, image, mealName, mealId, coordinates, onUpdateItem, onRefresh  }) => {
    // console.log("menuItem", name, price, description, image, mealName, mealId, coordinates, onUpdateItem, onRefresh)
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleEditDescription = () => {
    setIsDescriptionModalOpen(true);
  };

  const handleEditImage = () => {
    setIsImageModalOpen(true);
  };

  const handleDescriptionPublish = (newDescription) => {
    onUpdateItem({ description: newDescription });
  };
  
  const handleImagePublish = (newImageUrl) => {
    console.log("newImageUrl", newImageUrl)
    onUpdateItem({ image: newImageUrl });
    // onRefresh();
  };

  return (
    <div className="flex items-center bg-white rounded-xl shadow-sm p-4 transition-shadow hover:shadow-md">
      <div className="flex-grow pr-4">
        <h3 className="text-lg font-semibold text-gray-800 mb-1">{name}</h3>
        <div className="flex items-center space-x-2 mb-2">
          <p className="text-sm font-medium text-gray-700">{price}</p>
          
        </div>
        <div>
          {description ? (
            <p className="text-sm text-gray-600 mb-2">{description}</p>
          ) : null}
          <button
            onClick={handleEditDescription}
            className="text-sm text-blue-500 font-medium flex items-center hover:text-blue-600 transition-colors"
          >
            <Edit size={14} className="mr-1" /> 
            {description ? 'Edit description' : 'Add description'}
          </button>
        </div>
      </div>
      <div className="relative w-24 h-24 flex-shrink-0">
        {image ? (
          <img src={image} alt={name} className="w-full h-full object-cover rounded-lg shadow-sm" />
        ) : (
          <div className="w-full h-full bg-gray-200 rounded-lg flex items-center justify-center">
            <Plus size={24} className="text-gray-400" />
          </div>
        )}
        <button 
          className="absolute bottom-2 right-2 bg-white rounded-full p-1.5 shadow-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={handleEditImage}
        >
          <Edit size={12} className="text-gray-600" />
        </button>
      </div>
      
      <DescriptionGeneratorModal
        isOpen={isDescriptionModalOpen}
        onClose={() => setIsDescriptionModalOpen(false)}
        onPublish={handleDescriptionPublish}
        mealName={mealName}
        mealId={mealId}
        coordinates={coordinates}
      />

      <ImageUploadModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        onPublish={handleImagePublish}
        mealName={mealName}
        mealDescription={description}
        mealId={mealId}
      />
    </div>
  );
};

export default MenuItem;