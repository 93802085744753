import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ onDemoClick }) => {
  return (
    <nav className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <Link 
              to="/" 
              className="text-2xl font-light text-gray-800 hover:text-gray-600 transition-colors duration-200"
            >
              Meal Magic
            </Link>
          </div>
          <div>
            <button
              onClick={onDemoClick}
              className="px-5 py-2 bg-gray-900 text-white text-sm font-medium rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 transition-colors duration-200"
            >
              Try Demo
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;