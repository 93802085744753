import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';

const LandingPage = ({ onDemoClick }) => {
  const navigate = useNavigate();

  const handleDemoClick = async () => {
    await onDemoClick();
    navigate('/restaurant');
  };

  const pages = [
    {
      title: "Welcome to Meal Magic",
      description: (
        <p className="text-xl md:text-2xl mb-8">
          Make Your{' '}
          <img 
            src="/ubelogo.png" 
            alt="UberEats Logo" 
            className="inline-block w-6 h-6 md:w-8 md:h-8 align-middle mx-2" 
          />
          {' '}Menu Wonderful With Just a Click!
        </p>
      ),
      showDemo: true
    },
    {
      title: "AI-Powered Menu Descriptions",
      description: "Generate compelling dish descriptions instantly.",
      video: "/des_gen.mp4",
      isLarge: true
    },
    {
      title: "Market Analysis Integration",
      description: "Get insights on competitor pricing and popular dishes.",
      video: "/mkt_ana.mp4",
      isLarge: true
    },
    {
      title: "Smart Image Enhancement",
      description: "Automatically enhance your food photos for maximum appeal.",
      video: "/img_ret.mp4",
      isLarge: true
    },
    {
      title: "Related Images Suggestions",
      description: "Retrieve similar images to your dishes for a consistent look.",
      video: "/img_bea.mp4",
      isLarge: true
    },
  ];

  const TryDemoButton = () => (
    <button
      onClick={handleDemoClick}
      className="px-6 py-2 md:px-8 md:py-3 bg-gray-900 text-white text-base md:text-lg font-medium rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 transition-colors duration-200"
    >
      Try Demo
    </button>
  );

  return (
    <ReactFullpage
      licenseKey={'YOUR_KEY_HERE'}
      scrollingSpeed={1000}
      navigation
      navigationPosition="right"
      css3={true}
      scrollBar={false}
      autoScrolling={true}
      fitToSection={true}
      render={({ state, fullpageApi }) => (
        <ReactFullpage.Wrapper>
          {pages.map((page, index) => (
            <div key={index} className="section">
              {index === 0 ? (
                // First page remains centered
                <div className="flex flex-col items-center justify-center text-center h-full p-4 md:p-8">
                  <h1 className="text-3xl md:text-4xl font-bold mb-4">{page.title}</h1>
                  {page.description}
                  <TryDemoButton />
                </div>
              ) : (
                // Subsequent pages with responsive layout
                <div className="flex flex-col md:flex-row h-full">
                  <div className={`w-full md:w-1/2 flex items-center justify-center p-4 md:p-8 ${page.isLarge ? 'md:w-3/5' : 'md:w-1/2'}`}>
                    <video 
                      src={page.video}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className={`object-contain ${page.isLarge ? 'w-full h-auto max-h-[70vh]' : 'w-full h-auto max-h-[60vh]'}`}
                    >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className={`w-full md:w-1/2 flex flex-col items-center md:items-start justify-center p-4 md:p-8 text-center md:text-left ${page.isLarge ? 'md:w-2/5' : 'md:w-1/2'}`}>
                    <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">{page.title}</h1>
                    <p className="text-lg md:text-xl mb-4 md:mb-8">{page.description}</p>
                    <TryDemoButton />
                  </div>
                </div>
              )}
            </div>
          ))}
        </ReactFullpage.Wrapper>
      )}
    />
  );
};

export default LandingPage;