import React, { useState, useEffect } from 'react';
import { X, RotateCw, Send, ExternalLink } from 'lucide-react';
import axios from 'axios';
import LanguageSelector from './LanguageSelector';
import { Pinecone } from '@pinecone-database/pinecone';
import { pipeline, env } from '@xenova/transformers';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
env.allowLocalModels = false;
env.remoteModels = 'https://cdn.jsdelivr.net/npm/@xenova/transformers@2.6.0/models/';

const axiosInstance = axios.create({
  proxy: {
    host: '127.0.0.1',
    port: 7890,
    protocol: 'http'
  }
});

console.log('Pinecone API Key:', process.env.REACT_APP_PINECONE_API_KEY ? 'Set' : 'Not set');
console.log('Transformers env:', env);

const pc = new Pinecone({ 
  apiKey: process.env.REACT_APP_PINECONE_API_KEY,
});

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error: {this.state.error.message}</div>;
    }
    return this.props.children;
  }
}

// Debug Panel Component
// const DebugPanel = () => {
//   const [logs, setLogs] = useState([]);

//   useEffect(() => {
//     const originalLog = console.log;
//     const originalError = console.error;

//     console.log = (...args) => {
//       setLogs(prev => [...prev, { type: 'log', content: args.join(' ') }]);
//       originalLog.apply(console, args);
//     };

//     console.error = (...args) => {
//       setLogs(prev => [...prev, { type: 'error', content: args.join(' ') }]);
//       originalError.apply(console, args);
//     };

//     return () => {
//       console.log = originalLog;
//       console.error = originalError;
//     };
//   }, []);

//   return (
//     <div style={{
//       position: 'fixed',
//       bottom: 0,
//       left: 0,
//       right: 0,
//       maxHeight: '30vh',
//       overflowY: 'auto',
//       backgroundColor: 'rgba(0,0,0,0.8)',
//       color: 'white',
//       fontSize: '10px',
//       padding: '5px',
//       zIndex: 9999
//     }}>
//       {logs.map((log, index) => (
//         <div key={index} style={{ color: log.type === 'error' ? 'red' : 'white' }}>
//           {log.content}
//         </div>
//       ))}
//     </div>
//   );
// };

const DescriptionGeneratorModal = ({ isOpen, onClose, onPublish, mealName, mealId, coordinates  }) => {
  const [selectedStyle, setSelectedStyle] = useState('');
  const [generatedDescription, setGeneratedDescription] = useState('');
  const [userInput, setUserInput] = useState('');
  const [marketAnalysis, setMarketAnalysis] = useState([]);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const styles = ['Vegetarian', 'Healthy Ingredients', 'Gourmet', 'Quick and Easy'];

  useEffect(() => {
    console.log('useEffect triggered', { isOpen, mealName, coordinates });
    if (isOpen) {
      fetchMarketAnalysis();
    }
  }, [isOpen, mealName, coordinates]);
  

  const handleStyleClick = (style) => {
    setSelectedStyle(prevStyle => prevStyle === style ? '' : style);
  };

  const fetchMarketAnalysis = async () => {
    console.log('fetchMarketAnalysis started');
    try {
      console.log('Starting market analysis fetch for meal:', mealName);
  
      console.log('Creating embedding...');
      const extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');
      console.log('Embedding model loaded successfully', extractor);
      const embedding = await extractor(mealName, { pooling: 'mean', normalize: true });
      console.log('Embedding created successfully');
      console.log('Embedding shape:', embedding.data.length);

      const vectorArray = Array.from(embedding.data);
  
      console.log('Getting Pinecone index...');
      const index = pc.Index('mealmagic');
      console.log('Pinecone index retrieved');
  
      console.log('Querying Pinecone...');
      console.log('Query parameters:', {
        topK: 10,
        vector: vectorArray,
        includeMetadata: true
      });
  
      const queryResponse = await index.query({
        topK: 10,
        vector: vectorArray,
        includeMetadata: true
      });
      
      console.log('Pinecone query completed');
      console.log('Query response:', JSON.stringify(queryResponse, null, 2));
  
      console.log('Processing query results...');
      const analysisResults = queryResponse.matches.map(match => ({
        outlet_name: match.metadata.outlet_name || '',
        outlet_url: match.metadata.url || '',
        meal_name: match.metadata.name || '',
        description: match.metadata.description,
        image_url: match.metadata.image_url,
        price: match.metadata.price || ''
      }));
      console.log('Query results processed');
  
      setMarketAnalysis(analysisResults);
      console.log('Market analysis completed successfully');
    } catch (error) {
      console.error('Error in fetchMarketAnalysis:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      setMarketAnalysis([]);
    } finally {
      console.log('fetchMarketAnalysis completed');
    }
  };

  const handleGenerate = async () => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_DIFY_BASE_URL}/chat-messages`, {
        app_id: process.env.REACT_APP_DIFY_APP_ID,
        inputs: {
          meal_name: mealName,
          style: selectedStyle,
          detail_feedback: userInput,
          language: selectedLanguage
        },
        query: userInput || `Generate a description for this meal in ${selectedLanguage}`,
        user: 'user_identifier',
        response_mode: "blocking",
        conversation_id: conversationId
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_DIFY_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('API Response:', response.data);

      if (response.data.answer) {
        const newDescription = response.data.answer;
        setGeneratedDescription(newDescription);
        
        setConversationHistory(prevHistory => [
          ...prevHistory,
          { role: 'user', content: userInput },
          { role: 'assistant', content: newDescription }
        ]);

        setUserInput('');
        
        if (response.data.conversation_id) {
          setConversationId(response.data.conversation_id);
        }
      } else {
        console.error('Unexpected response structure:', response.data);
        alert('Received an unexpected response from the server. Please try again.');
      }
    } catch (error) {
      console.error('Error generating/refining description:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      alert('Failed to generate/refine description. Please try again.');
    }
  };

  const handlePublish = async () => {
    console.log('Publishing description:',mealId, generatedDescription);
    try {
      await axiosInstance.put(`${API_BASE_URL}/api/meals/${mealId}`, { description: generatedDescription });
      onPublish(generatedDescription);
      onClose();
    } catch (error) {
      console.error('Error publishing description:', error);
      alert('Failed to publish description. Please try again.');
    }
  };

  const handleLanguageChange = (languageCode) => {
    setSelectedLanguage(languageCode);
  };

  if (!isOpen) return null;

  return (
    <ErrorBoundary>
      <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 p-2">
        <div className="bg-white bg-opacity-75 rounded-2xl shadow-2xl w-full max-w-lg md:max-w-4xl h-[95vh] md:h-[80vh] flex flex-col overflow-hidden">
          <div className="flex justify-between items-center p-3 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-800">Create Description</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
              <X size={20} />
            </button>
          </div>
          
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden">
            {/* Description Generation Section */}
            <div className="w-full md:w-1/2 flex-1 md:flex-none p-3 flex flex-col md:border-r border-gray-200 overflow-y-auto">
              <h3 className="text-base font-semibold mb-2 text-gray-700">Description Style</h3>
              <div className="flex flex-wrap gap-1 mb-2">
                {styles.map((style) => (
                  <button
                    key={style}
                    className={`px-2 py-1 rounded-full text-xs font-semibold transition-colors ${
                      selectedStyle === style 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                    onClick={() => handleStyleClick(style)}
                  >
                    {style}
                  </button>
                ))}
              </div>
              <div className="relative flex-grow mb-2">
                <textarea
                  className="w-full h-full p-2 pr-24 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={generatedDescription}
                  onChange={(e) => setGeneratedDescription(e.target.value)}
                  placeholder="Generated description will appear here..."
                />
                <div className="absolute bottom-2 right-2">
                  <LanguageSelector
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={handleLanguageChange}
                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-xs"
                  placeholder="Add more details about the dish..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                />
                <button
                  className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  onClick={handleGenerate}
                >
                  <RotateCw size={16} />
                </button>
              </div>
            </div>

            {/* Market Analysis Section */}
            <div className="w-full md:w-1/2 flex-1 md:flex-none p-3 overflow-y-auto">
              <h3 className="text-base font-semibold mb-2 text-gray-700">Market Analysis</h3>
              {marketAnalysis.length > 0 ? (
                <div className="space-y-3">
                  {marketAnalysis.map((item, index) => (
                    <div key={index} className="border p-2 rounded-lg shadow-sm">
                      <a 
                        href={item.outlet_url} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="font-semibold text-blue-600 hover:text-blue-800 flex items-center text-xs"
                      >
                        {item.outlet_name}
                        <ExternalLink size={12} className="ml-1" />
                      </a>
                      <p className="text-xs text-gray-600">{item.meal_name}</p>
                      {item.description && (
                        <p className="text-xs text-gray-500 mt-1">{item.description}</p>
                      )}
                      <p className="text-xs font-medium mt-1">Price: {item.price}</p>
                      {item.image_url && (
                        <img src={item.image_url} alt={item.meal_name} className="w-full h-24 object-cover mt-1 rounded" />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-600 text-xs">The market analysis feature is only available on the desktop version. Please visit from a desktop device to use this feature.</p>
              )}
            </div>
          </div>

          <div className="p-3 border-t border-gray-200">
            <button
              className="w-full bg-blue-500 text-white px-3 py-2 rounded-full text-sm font-semibold hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              onClick={handlePublish}
              disabled={!generatedDescription.trim()}
            >
              <Send size={16} className="mr-2" />
              Publish Description
            </button>
          </div>
        </div>
        {/* <DebugPanel /> */}
      </div>
    </ErrorBoundary>
  );
};

export default DescriptionGeneratorModal;