import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-5xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center text-center">
          <p className="text-xs text-gray-500">
            Copyright © {currentYear} Dashmote. Innovation Friday.
          </p>
          <p className="mt-1 text-xs text-gray-500">
            Meal Magic Team
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;