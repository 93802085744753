import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; 
import RestaurantPage from './components/RestaurantPage';
import LandingPage from './components/LandingPage';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const mockRestaurantData = {
  "name": "Big Sharks Fish and Chicken (Michigan Rd)",
  "rating": "4.3",
  "ratingCount": "100+",
  "cuisineTypes": ["Comfort Food", "American", "Wings"],
  "address": "8421 N Michigan Rd, A, Indianapolis, IN 46268",
  "openUntil": "12:00 PM",
  "lat": 39.9088417,
  "lon": -86.2231571,
  "menuCategories": ["Chicken Wings", "Buffalo or Honey BBQ Wings", "Boneless Wings", "Sandwiches", "Chicken Dinners", "Chicken Combo Dinners", "Combos", "Chicken Combo Dinners", "Family Meals", "Fish Dinners", "Kid's Meals", "Side Orders", "Desserts", "Drinks", "Extra Sauce"],
  "menuItems": {
    "Chicken Wings": [
      {
        "name": "Wings",
        "price": "$12.99",
        "featured": true,
        "description": "Served with fries, bread, sauce on the side and 32oz Coke slaw",
        "image": "https://tb-static.uber.com/prod/image-proc/processed_images/88aaeaafb632fa3bb23affc4c1be38b2/859baff1d76042a45e319d1de80aec7a.jpeg"
      },
      {
        "name": "Wings & Fries",
        "price": "$8.99",
        "featured": false,
        "description": null,
        "image": "https://tb-static.uber.com/prod/image-proc/processed_images/88aaeaafb632fa3bb23affc4c1be38b2/859baff1d76042a45e319d1de80aec7a.jpeg"
      }
    ],
    "Buffalo or Honey BBQ Wings": [
      {
        "name": "Wings Dinner (1/2)",
        "price": "$11.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Wings+Dinner"
      }
    ],
    "Boneless Wings": [
      {
        "name": "Wings Dinner",
        "price": "$11.99",
        "featured": false,
        "description": "Boneless wings",
        "image": "https://via.placeholder.com/80x80.png?text=Boneless+Wings"
      }
    ],
    "Sandwiches": [
      {
        "name": "Gyro Sandwich",
        "price": "$14.99",
        "featured": false,
        "description": "Served with fries",
        "image": "https://via.placeholder.com/80x80.png?text=Gyro+Sandwich"
      },
      {
        "name": "Crispy Chicken Sandwich",
        "price": "$14.99",
        "featured": false,
        "description": "Served with fries",
        "image": "https://via.placeholder.com/80x80.png?text=Crispy+Chicken"
      },
      {
        "name": "Fish Sandwich",
        "price": "$14.99",
        "featured": false,
        "description": "Served with fries",
        "image": "https://via.placeholder.com/80x80.png?text=Fish+Sandwich"
      },
      {
        "name": "Shrimp Sandwich",
        "price": "$14.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Shrimp+Sandwich"
      }
    ],
    "Chicken Dinners": [
      {
        "name": "Chicken Gizzards Dinner",
        "price": "$11.99",
        "featured": false,
        "description": "Served with fries, coleslaw and bread. All dinners are served with fries, coleslaw, and bread",
        "image": "https://via.placeholder.com/80x80.png?text=Gizzards+Dinner"
      },
      {
        "name": "Chicken Tender Dinner",
        "price": "$11.99",
        "featured": false,
        "description": "Served with fries, coleslaw and bread. All dinners are served with fries, coleslaw, and bread",
        "image": "https://via.placeholder.com/80x80.png?text=Tender+Dinner"
      },
      {
        "name": "Chicken Nuggets Dinner",
        "price": "$11.99",
        "featured": false,
        "description": "Served with fries, coleslaw and bread. All dinners are served with fries, coleslaw, and bread",
        "image": "https://via.placeholder.com/80x80.png?text=Nuggets+Dinner"
      }
    ],
    "Combos": [
      {
        "name": "3 Items Combos",
        "price": "$24.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Wings+Combo"
      },
      {
        "name": "2 Items Combos",
        "price": "$19.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Wings+Combo"
      }
    ],
    "Chicken Combo Dinners": [
      {
        "name": "Chicken Combo Dinner (4 Mix)",
        "price": "$14.99",
        "featured": false,
        "description": "Served with fries, bread, sauce on the side and 32oz Coke slaw",
        "image": "https://via.placeholder.com/80x80.png?text=Combo+4+Mix"
      },
      {
        "name": "Chicken Combo Dinner (8 Mix)",
        "price": "$23.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Combo+8+Mix"
      },
      {
        "name": "Chicken Combo Dinner (12 Mix)",
        "price": "$32.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Combo+12+Mix"
      },
      {
        "name": "Chicken Combo Dinner (16 Mix)",
        "price": "$39.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Combo+16+Mix"
      },
      {
        "name": "Chicken Combo Dinner (24 Mix)",
        "price": "$55.99",
        "featured": false,
        "description": null,
        "image": "https://via.placeholder.com/80x80.png?text=Combo+24+Mix"
      }
    ],
    "Family Meals": [
      {
          "name": "Catfish Fillet",
          "price": "$44.99",
          "featured": true,
          "description": "Served with fries, and sauce on the side",
          "image": "https://tb-static.uber.com/prod/image-proc/processed_images/743ba25bf384530caef3d8953f901a1f/859baff1d76042a45e319d1de80aec7a.jpeg"
      },
      {
          "name": "Tilapia Fillet",
          "price": "$44.99",
          "featured": false,
          "description": "Served with fries, and sauce on the side",
          "image": "https://via.placeholder.com/128x128.png?text=Tilapia+Fillet"
      }
    ],
    "Fish Dinners": [
      {
          "name": "Catfish Fillets",
          "price": "$8.99",
          "featured": true,
          "description": "Served with fries, and sauce on the side",
          "image": "https://tb-static.uber.com/prod/image-proc/processed_images/873ce2165a9ba4626749a2cecc7609c4/859baff1d76042a45e319d1de80aec7a.jpeg"
      },
      {
          "name": "Catfish Nuggets",
          "price": "$8.99",
          "featured": true,
          "description": "Served with fries, and sauce on the side",
          "image": "https://tb-static.uber.com/prod/image-proc/processed_images/73a59c80298cde73a8c44a0c95a62e8e/859baff1d76042a45e319d1de80aec7a.jpeg"
      },
      {
          "name": "Jumbo Shrimp",
          "price": "$14.99",
          "featured": true,
          "description": "Served with fries, and sauce on the side",
          "image": "https://tb-static.uber.com/prod/image-proc/processed_images/1d19d69a061e9f1f19e3ebb5c81476be/859baff1d76042a45e319d1de80aec7a.jpeg"
      },
      {
          "name": "Shrimp",
          "price": "$14.99",
          "featured": false,
          "description": "Served with fries, bread, sauce on the side, and 2oz Cole slaw",
          "image": "https://tb-static.uber.com/prod/image-proc/processed_images/1d19d69a061e9f1f19e3ebb5c81476be/859baff1d76042a45e319d1de80aec7a.jpeg"
        }
  ],
    "Kid's Meals": [
      {
          "name": "Wings (2 pcs), Fries & Small Drink",
          "price": "$7.99",
          "featured": false,
          "description": null,
          "image": "https://tb-static.uber.com/prod/image-proc/processed_images/112ffa2f18fc97919204f19b99851ecb/859baff1d76042a45e319d1de80aec7a.jpeg"
      },
      {
          "name": "Chicken Nuggets (5 pcs), Fries & Small Drink",
          "price": "$7.99",
          "featured": false,
          "description": null,
          "image": "https://via.placeholder.com/128x128.png?text=Chicken+Nuggets+Combo"
      }
    ],
    "Side Orders": [
      {
          "name": "Okra",
          "price": "$2.99",
          "featured": false,
          "description": "6-8 oz",
          "image": "https://via.placeholder.com/128x128.png?text=Okra"
      },
      {
          "name": "Fries",
          "price": "$2.99",
          "featured": false,
          "description": null,
          "image": "https://via.placeholder.com/128x128.png?text=Fries"
      }
    ],
    "Desserts": [
      {
          "name": "Banana Pudding",
          "price": "$4.99",
          "featured": false,
          "description": null,
          "image": "https://via.placeholder.com/128x128.png?text=Banana+Pudding"
      }
    ],
    "Drinks": [
      {
          "name": "Soda",
          "price": "$1.99",
          "featured": false,
          "description": "16 oz",
          "image": "https://via.placeholder.com/128x128.png?text=Soda"
      },
      {
          "name": "2 Liter",
          "price": "$2.99",
          "featured": false,
          "description": null,
          "image": "https://via.placeholder.com/128x128.png?text=2+Liter"
      }
    ],
    "Extra Sauce": [
      {
          "name": "Cup 2oz Sauce - Mild (Honey BBQ)",
          "price": "$0.50",
          "featured": false,
          "description": null,
          "image": "https://via.placeholder.com/128x128.png?text=Honey+BBQ"
      },
      {
          "name": "Cup 2oz Sauce - Hot Cajun Sauce",
          "price": "$0.50",
          "featured": false,
          "description": null,
          "image": "https://via.placeholder.com/128x128.png?text=Hot+Cajun"
      }
    ]
  }
};

const AppContent = () => {
  const [restaurantData, setRestaurantData] = useState(null);
  const navigate = useNavigate();

  const handleDemoClick = useCallback(async () => {
    console.log('Demo button clicked');
    try {
      console.log('Fetching data from API...');
      const response = await axios.get(`${API_BASE_URL}/api/random-demo`);
      console.log('API response:', response.data);
      setRestaurantData(response.data);
      // setRestaurantData(mockRestaurantData);
      navigate('/restaurant');
    } catch (error) {
      console.error('Error fetching demo data:', error);
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar onDemoClick={handleDemoClick} />
      <div className="pt-16 px-4 sm:px-6 lg:px-8 max-w-5xl mx-auto">
        <Routes>
          <Route path="/" element={<LandingPage onDemoClick={handleDemoClick} />} />
          <Route 
            path="/restaurant" 
            element={restaurantData ? <RestaurantPage restaurantData={restaurantData} /> : <LandingPage onDemoClick={handleDemoClick} />} 
          />
        </Routes>
      </div>
      <Footer /> 
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;