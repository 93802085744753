import React, { useState } from 'react';
import { Edit, Plus } from 'lucide-react';
import ImageUploadModal from './ImageUploadModal';

const FeaturedItem = ({ name, price, image, onEditImage }) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleEditImage = () => {
    setIsImageModalOpen(true);
  };

  const handlePublishImage = (newImage) => {
    onEditImage(name, newImage);
    setIsImageModalOpen(false);
  };

  return (
    <div className="w-40 flex-shrink-0 bg-white rounded-xl shadow-sm transition-shadow hover:shadow-md overflow-hidden">
      <div className="relative w-40 h-40">
        {image ? (
          <img src={image} alt={name} className="w-full h-full object-cover" />
        ) : (
          <div className="absolute inset-0 bg-gray-200 flex items-center justify-center">
            <Plus size={32} className="text-gray-400" />
          </div>
        )}
        <button 
          className="absolute bottom-2 right-2 bg-white rounded-full p-1.5 shadow-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={handleEditImage}
        >
          <Edit size={12} className="text-gray-600" />
        </button>
      </div>
      <div className="p-3">
        <h4 className="text-sm font-semibold text-gray-800 truncate">{name}</h4>
        <p className="text-xs text-gray-600 mt-1">{price}</p>
      </div>

      <ImageUploadModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        onPublish={handlePublishImage}
        mealName={name}
      />
    </div>
  );
};

export default FeaturedItem;