import React, { useRef, useState, useMemo } from 'react';
import { Star, MapPin, Clock, Search, ChevronLeft, ChevronRight } from 'lucide-react';
import MenuItem from './MenuItem';
import axios from 'axios'; 
import FeaturedItem from './FeaturedItem';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RestaurantPage = ({ restaurantData }) => {
  const {
    name,
    rating,
    ratingCount,
    cuisineTypes,
    address,
    openUntil,
    menuCategories,
    menuItems
  } = restaurantData;

  const [activeCategory, setActiveCategory] = useState(menuCategories[0]);
  const [menuItemsState, setMenuItemsState] = useState(menuItems);
  const sectionRefs = useRef({});
  const featuredItemsRef = useRef(null);

//   const handleUpdateMenuItem = (category, index, updates) => {
//     setMenuItemsState(prevState => {
//       const newState = {...prevState};
//       newState[category][index] = {...newState[category][index], ...updates};
//       return newState;
//     });
//   };

  // 从所有菜品中筛选出 featured 项目
  const featuredItems = useMemo(() => {
    return Object.values(menuItems).flat().filter(item => item.featured);
  }, [menuItems]);

  const handleEditImage = (itemName) => {
    console.log(`Editing image for item: ${itemName}`);
  };

  const handleUpdateMenuItem = async (category, index, updates) => {
    const item = menuItemsState[category][index];
    try {
      const response = await axios.put(`${API_BASE_URL}/api/meals/${item.id}`, updates);
      setMenuItemsState(prevState => {
        const newState = {...prevState};
        newState[category][index] = {...newState[category][index], ...response.data};
        return newState;
      });
    } catch (error) {
      console.error('Error updating menu item:', error);
    }
  };

  const handleRefreshMenuItem = async (category, index) => {
    const item = menuItemsState[category][index];
    try {
      const response = await axios.get(`${API_BASE_URL}/api/meals/${item.id}`);
      setMenuItemsState(prevState => {
        const newState = {...prevState};
        newState[category][index] = response.data;
        return newState;
      });
    } catch (error) {
      console.error('Error refreshing menu item:', error);
    }
  };

 

  const scrollToSection = (category) => {
    setActiveCategory(category);
    sectionRefs.current[category]?.scrollIntoView({ behavior: 'smooth' });
  };

  const scroll = (direction) => {
    const container = featuredItemsRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth / 2;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 md:p-6 bg-gray-50">
      {/* Restaurant Info Section */}
      <div className="bg-white rounded-2xl shadow-lg p-4 md:p-6 mb-6 md:mb-8">
        <h1 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4 text-gray-800">{name}</h1>
        <div className="flex flex-wrap items-center text-sm md:text-base text-gray-600 mb-2 md:mb-4">
          <div className="flex items-center mr-4 mb-2">
            <Star className="text-yellow-400 mr-1" size={18} />
            <span className="font-semibold mr-1 text-gray-800">{rating}</span>
            <span>({ratingCount} ratings)</span>
          </div>
          <span className="mb-2 px-3 py-1 bg-gray-200 rounded-full">{cuisineTypes.join(' • ')}</span>
        </div>
        <div className="flex items-center text-sm md:text-base text-gray-600 mb-2">
          <MapPin size={18} className="mr-2 flex-shrink-0 text-gray-400" />
          <span className="break-words">{address}</span>
        </div>
        <div className="flex items-center text-sm md:text-base text-gray-600">
          <Clock size={18} className="mr-2 flex-shrink-0 text-gray-400" />
          <span>Open until {openUntil}</span>
        </div>
      </div>
      
      {/* Featured Items Section */}
      {featuredItems.length > 0 && (
        <div className="mb-6 md:mb-8 bg-white rounded-2xl shadow-lg p-4 md:p-6">
          <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-800">Featured Items</h2>
          <div className="relative">
            <button 
              onClick={() => scroll('left')} 
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 hover:bg-gray-100 transition-colors"
            >
              <ChevronLeft size={24} />
            </button>
            <div 
              ref={featuredItemsRef} 
              className="flex overflow-x-auto pb-4 scrollbar-hide"
              style={{ scrollBehavior: 'smooth' }}
            >
              {featuredItems.map((item, index) => (
                <div key={index} className="flex-shrink-0 mr-4 md:mr-6 last:mr-0">
                  <FeaturedItem {...item} onEditImage={handleEditImage} />
                </div>
              ))}
            </div>
            <button 
              onClick={() => scroll('right')} 
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10 hover:bg-gray-100 transition-colors"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      )}
      
      {/* Menu Section */}
      <div className="bg-white rounded-2xl shadow-lg p-4 md:p-6">
        <div className="flex flex-col md:flex-row md:items-center mb-4 md:mb-6">
          <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-2 md:mb-0 md:mr-4">Menu</h2>
          <div className="relative flex-grow">
            <Search size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder={`Search in ${name}`}
              className="w-full pl-10 pr-4 py-2 border rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        
        <div className="flex overflow-x-auto mb-4 md:mb-6 pb-2 scrollbar-hide">
          {menuCategories.map((category) => (
            <button 
              key={category} 
              className={`mr-2 md:mr-4 last:mr-0 whitespace-nowrap text-sm font-medium px-3 md:px-4 py-1 md:py-2 rounded-full transition-colors ${
                activeCategory === category 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => scrollToSection(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {menuCategories.map((category) => (
          <div 
            key={category} 
            className="mt-6 md:mt-8"
            ref={(el) => (sectionRefs.current[category] = el)}
          >
            <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-gray-800">{category}</h3>
                <div className="space-y-4 md:space-y-6">
                    {menuItemsState[category].map((item, index) => (
                        <MenuItem 
                            key={item.id}
                            name={item.name}
                            price={item.price}
                            description={item.description}
                            image={item.image}
                            mealName={item.name}
                            mealId={item.id}
                            coordinates={{ lat: restaurantData.lat, lon: restaurantData.lon }}
                            onUpdateItem={(updates) => handleUpdateMenuItem(category, index, updates)}
                            onRefresh={() => handleRefreshMenuItem(category, index)}
                        />
                    ))}
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default RestaurantPage;