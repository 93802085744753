import React, { useState, useEffect } from 'react';
import { Upload, X, Send, Camera, Loader, AlertCircle } from 'lucide-react';
import axios from 'axios';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const TAVILY_API_KEY = process.env.REACT_APP_TAVILY_API_KEY;

const axiosInstance = axios.create({
  proxy: {
    host: '127.0.0.1',
    port: 7890,
    protocol: 'http'
  }
});

const ImageUploadModal = ({ isOpen, onClose, onPublish, mealName, mealDescription, mealId }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [showSuggestedImages, setShowSuggestedImages] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [enhancedImages, setEnhancedImages] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  const resetState = () => {
    setUploadedImage(null);
    setSelectedImage(null);
    setGeneratedImages([]);
    setShowSuggestedImages(false);
    setIsEnhancing(false);
    setEnhancedImages({});
  };

  useEffect(() => {
    if (isOpen) {
      // 可以在这里添加一些初始化逻辑
    } else {
      resetState();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && showSuggestedImages) {
      fetchSuggestedImages();
    }
  }, [isOpen, showSuggestedImages, mealName, mealDescription]);

  // const fetchSuggestedImages = async () => {
  //   try {
  //     console.log('Fetching images for:', mealName, mealDescription);
  //     if (!mealName) {
  //       console.error('Meal name is required');
  //       return;
  //     }
  //     const response = await axiosInstance.post('/api/suggested-images', {
  //       title: mealName,
  //       description: mealDescription || ''
  //     });
  //     console.log('Suggested images:', response.data);
  //     if (response.data && response.data.images) {
  //       setGeneratedImages(response.data.images);
  //     } else {
  //       console.error('Unexpected response format:', response.data);
  //       setGeneratedImages([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching suggested images:', error);
  //     if (error.response) {
  //       console.error('Response data:', error.response.data);
  //       console.error('Response status:', error.response.status);
  //     }
  //     alert('Failed to fetch suggested images. Please try again.');
  //     setGeneratedImages([]);
  //   }
  // };

  const fetchSuggestedImages = async () => {
    try {
      console.log('Fetching images for:', mealName, mealDescription);
      if (!mealName) {
        console.error('Meal name is required');
        return;
      }
      const result = await getSuggestedImages(mealName, mealDescription || '');
      setGeneratedImages(result.images);
    } catch (error) {
      console.error('Error fetching suggested images:', error);
      alert('Failed to fetch suggested images. Please try again.');
      setGeneratedImages([]);
    }
  };

  async function getSuggestedImages(title, description) {
    try {
      const query = `${title} ${description}`;
      console.log('Sending request to Tavily API with query:', query);
      
      const requestBody = {
        api_key: TAVILY_API_KEY,  // 将 API 密钥添加到请求体中
        query: query,
        search_depth: "advanced",
        include_images: true,
        max_results: 10
      };

      const response = await fetch('https://api.tavily.com/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      const responseText = await response.text();
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}, body: ${responseText}`);
      }
  
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('Error parsing JSON:', e);
        throw new Error('Invalid JSON in response');
      }
  
      const imageUrls = data.images || [];  // Tavily 可能直接返回 images 数组
      return { images: imageUrls.slice(0, 5) };
    } catch (error) {
      console.error("Error in getSuggestedImages:", error);
      throw error;
    }
  }

  const handleImageSelect = (img) => {
    setSelectedImage(img);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImage(e.target.result);
      };
      reader.readAsDataURL(file);
      await enhanceImageWithCloudinary(file);
    }
  };

  const enhanceImageWithCloudinary = async (file) => {
    setIsEnhancing(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

      const response = await axiosInstance.post(
        `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
        formData
      );

      const publicId = response.data.public_id;
      const baseUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/`;

      const enhancedUrls = {
        gourmet: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_saturation:25/e_contrast:25/e_vibrance:30/e_sharpen:75/${publicId}`,
        freshnessBoost: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_auto_color/e_vibrance:35/e_brightness:5/e_gamma:-20/${publicId}`,
        // warmAndCozy: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_warmth:30/e_saturation:15/e_gamma:-10/e_vignette:30/${publicId}`,
        // crispAndClear: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_sharpen:90/e_clarity:30/e_auto_contrast/e_vibrance:20/${publicId}`,
        foodMagazine: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_improve/e_saturation:30/e_contrast:30/e_sharpen:100/${publicId}`,
        crispAndClear: `${baseUrl}e_sharpen:50/${publicId}`,
        dramaticPlating: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_art:zorro/e_contrast:25/e_vibrance:25/e_sharpen:50/${publicId}`,
        foodMagazineCover: `${baseUrl}c_scale,w_800,h_800,c_fill,g_auto,q_auto:best/e_improve/e_saturation:30/e_contrast:30/e_vignette:25/e_sharpen:100/${publicId}`
      };

      setEnhancedImages(enhancedUrls);
      setSelectedImage(enhancedUrls.gourmet);
      setImageErrors({}); // 重置错误状态
    } catch (error) {
      console.error('Error enhancing image:', error);
      alert('Failed to enhance image. Please try again.');
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleImageError = (style) => {
    setImageErrors(prev => ({ ...prev, [style]: true }));
  };

  const handlePublish = async () => {
    if (!selectedImage) {
      alert('Please select an image before publishing.');
      return;
    }
    try {
      console.log('Publishing image:', mealId, selectedImage);
      await axiosInstance.put(`${API_BASE_URL}/api/meals/${mealId}`, { image_url: selectedImage });
      onPublish(selectedImage);
      onClose();
    } catch (error) {
      console.error('Error publishing image:', error);
      alert('Failed to publish image. Please try again.');
    }
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 p-2">
      <div className="bg-white bg-opacity-75 rounded-2xl shadow-2xl w-full max-w-lg md:max-w-4xl h-[95vh] md:h-[80vh] flex flex-col overflow-hidden">
        <div className="flex justify-between items-center p-3 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-800">Enhance Your Food Image</h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors">
            <X size={20} />
          </button>
        </div>
        
        
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden">
          {/* Upload section */}
          <div className="w-full md:w-1/2 flex-1 md:flex-none p-3 flex flex-col md:border-r border-gray-200 overflow-y-auto">
            <h3 className="text-base font-semibold mb-2 text-gray-700">Upload Your Food Picture</h3>
            <div className="border-2 border-dashed border-gray-300 rounded-xl p-4 text-center mb-3 bg-gray-50 hover:bg-gray-100 transition-colors">
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleFileUpload}
                className="hidden"
                id="file-upload"
              />
              <label htmlFor="file-upload" className="cursor-pointer flex flex-col items-center">
                <div className="flex space-x-2 mb-2">
                  <Upload size={20} className="text-gray-400" />
                  <Camera size={20} className="text-gray-400" />
                </div>
                <p className="text-sm text-gray-600">Click to upload or take a food photo</p>
                <p className="text-xs text-gray-500 mt-1">PNG, JPG, GIF up to 10MB</p>
              </label>
            </div>
            {uploadedImage && (
              <div className="mt-3 flex-1 overflow-y-auto">
              <h4 className="text-sm font-semibold mb-2 text-gray-700">Enhanced Food Images</h4>
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(enhancedImages).map(([style, url]) => (
                  <div key={style} className="mb-2">
                    <p className="text-xs text-gray-600 mb-1 capitalize">{style.replace(/([A-Z])/g, ' $1').trim()}</p>
                    {isEnhancing ? (
                      <div className="w-full pb-[100%] relative bg-gray-100 rounded-lg">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <Loader className="animate-spin text-blue-500" size={16} />
                        </div>
                      </div>
                    ) : imageErrors[style] ? (
                      <div className="w-full pb-[100%] relative bg-gray-100 rounded-lg flex items-center justify-center">
                        <AlertCircle className="text-red-500" size={24} />
                        <p className="text-xs text-gray-500 mt-1">Failed to load</p>
                      </div>
                    ) : (
                      <div 
                        className={`w-full pb-[100%] relative cursor-pointer rounded-lg overflow-hidden ${selectedImage === url ? 'ring-2 ring-blue-500' : ''}`}
                        onClick={() => setSelectedImage(url)}
                      >
                        <img 
                          src={url} 
                          alt={style} 
                          className="absolute inset-0 w-full h-full object-cover" 
                          onError={() => handleImageError(style)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            )}
          </div>

          {/* Suggested Images section */}
          <div className="w-full md:w-1/2 flex-1 md:flex-none p-3 overflow-y-auto">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-base font-semibold text-gray-700">Suggested Images</h3>
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input 
                    type="checkbox" 
                    className="sr-only" 
                    checked={showSuggestedImages} 
                    onChange={() => setShowSuggestedImages(!showSuggestedImages)} 
                  />
                  <div className={`block w-8 h-5 rounded-full ${showSuggestedImages ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
                  <div className={`dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition ${showSuggestedImages ? 'transform translate-x-3' : ''}`}></div>
                </div>
                <div className="ml-2 text-sm text-gray-700 font-medium">
                  {showSuggestedImages ? 'On' : 'Off'}
                </div>
              </label>
            </div>
            {showSuggestedImages && (
              <div className="grid grid-cols-2 gap-2 overflow-y-auto flex-1">
                {generatedImages.map((img, index) => (
                  <div 
                    key={index} 
                    className={`relative cursor-pointer rounded-lg overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg ${
                      selectedImage === img ? 'ring-2 ring-blue-500' : ''
                    }`}
                    onClick={() => handleImageSelect(img)}
                  >
                    <div className="w-full pb-[100%] relative">
                      <img 
                        src={img} 
                        alt={`Generated ${index + 1}`} 
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="p-3 border-t border-gray-200">
          <button
            className="w-full bg-blue-500 text-white px-3 py-2 rounded-full text-sm font-semibold hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            onClick={handlePublish}
            disabled={!selectedImage || isEnhancing}
          >
            <Send size={16} className="mr-2" />
            Publish Enhanced Food Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;
